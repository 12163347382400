import React from 'react';
import { useIntl } from 'react-intl';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';
import FormContainer from '../Form/form-container';
import FormHeadline from '../FormHeadline/form-headline';
import CheckBox from '../Form/checkbox';
import OvenIcon from '../Icons/oven';
import OvenCrossed from '../Icons/oven-crossed';

export default function Oven({ set, oven }) {
  const intl = useIntl();
  const nextStepPath = '/get-quote/date';

  return(
    <FormContainer>
      <FormHeadline>{intl.formatMessage({ id: "quoteOvenHeadline" })}</FormHeadline>

      <Link to={nextStepPath}>
        <CheckBox checked={oven === 'yes'} handleClick={() => set({oven: 'yes'})}>
          <OvenIcon color={'#333'} />
          <div>{intl.formatMessage({ id: "quoteYes" })}</div>
        </CheckBox>
      </Link>

      <Link to={nextStepPath}>
        <CheckBox checked={oven === 'no'} handleClick={() => set({oven: 'no'})}>
          <OvenCrossed color={'#333'} />
          <div>{intl.formatMessage({ id: "quoteNo" })}</div>
        </CheckBox>
      </Link>
    </FormContainer>
  );
}
