import React from 'react';

export default function Oven({ color }) {
  return(
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>oven</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact-Oven" transform="translate(-148.000000, -204.000000)">
          <g id="yes" transform="translate(20.000000, 190.000000)">
            <g id="Group" transform="translate(0.000000, 14.000000)">
              <g id="oven" transform="translate(128.000000, 0.000000)">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <path d="M22.6540541,0.972972973 L1.02162162,0.972972973 C0.815634337,0.972972973 0.648648649,1.13995866 0.648648649,1.34594595 L0.648648649,22.9783784 C0.648648649,23.1843657 0.815634337,23.3513514 1.02162162,23.3513514 L22.6540541,23.3513514 C22.8600413,23.3513514 23.027027,23.1843657 23.027027,22.9783784 L23.027027,1.34594595 C23.027027,1.13995866 22.8600413,0.972972973 22.6540541,0.972972973 Z M22.2810811,22.6054054 L1.39459459,22.6054054 L1.39459459,6.19459459 L22.2810811,6.19459459 L22.2810811,22.6054054 Z M22.2810811,5.44864865 L1.39459459,5.44864865 L1.39459459,1.71891892 L22.2810811,1.71891892 L22.2810811,5.44864865 Z" id="Shape" fill={color} fillRule="nonzero"></path>
                <path d="M8.81081081,4.54054054 L14.8648649,4.54054054 C15.0738375,4.54054054 15.2432432,4.37113477 15.2432432,4.16216216 L15.2432432,2.64864865 C15.2432432,2.43967604 15.0738375,2.27027027 14.8648649,2.27027027 L8.81081081,2.27027027 C8.6018382,2.27027027 8.43243243,2.43967604 8.43243243,2.64864865 L8.43243243,4.16216216 C8.43243243,4.37113477 8.6018382,4.54054054 8.81081081,4.54054054 Z M9.18918919,3.02702703 L14.4864865,3.02702703 L14.4864865,3.78378378 L9.18918919,3.78378378 L9.18918919,3.02702703 Z" id="Shape" fill={color} fillRule="nonzero"></path>
                <path d="M3.72972973,4.54054054 C4.35664755,4.54054054 4.86486486,4.03232323 4.86486486,3.40540541 C4.86486486,2.77848758 4.35664755,2.27027027 3.72972973,2.27027027 C3.10281191,2.27027027 2.59459459,2.77848758 2.59459459,3.40540541 C2.59529836,4.03203149 3.10310365,4.53983677 3.72972973,4.54054054 L3.72972973,4.54054054 Z M3.72972973,3.02702703 C3.93870234,3.02702703 4.10810811,3.1964328 4.10810811,3.40540541 C4.10810811,3.61437801 3.93870234,3.78378378 3.72972973,3.78378378 C3.52075712,3.78378378 3.35135135,3.61437801 3.35135135,3.40540541 C3.35163793,3.19655162 3.52087594,3.02731361 3.72972973,3.02702703 Z" id="Shape" fill={color} fillRule="nonzero"></path>
                <path d="M6.64864865,4.54054054 C7.27556647,4.54054054 7.78378378,4.03232323 7.78378378,3.40540541 C7.78378378,2.77848758 7.27556647,2.27027027 6.64864865,2.27027027 C6.02173082,2.27027027 5.51351351,2.77848758 5.51351351,3.40540541 C5.51421728,4.03203149 6.02202257,4.53983677 6.64864865,4.54054054 L6.64864865,4.54054054 Z M6.64864865,3.02702703 C6.85762126,3.02702703 7.02702703,3.1964328 7.02702703,3.40540541 C7.02702703,3.61437801 6.85762126,3.78378378 6.64864865,3.78378378 C6.43967604,3.78378378 6.27027027,3.61437801 6.27027027,3.40540541 C6.27055685,3.19655162 6.43979486,3.02731361 6.64864865,3.02702703 Z" id="Shape" fill={color} fillRule="nonzero"></path>
                <path d="M17.027027,4.54054054 C17.6539449,4.54054054 18.1621622,4.03232323 18.1621622,3.40540541 C18.1621622,2.77848758 17.6539449,2.27027027 17.027027,2.27027027 C16.4001092,2.27027027 15.8918919,2.77848758 15.8918919,3.40540541 C15.8925957,4.03203149 16.4004009,4.53983677 17.027027,4.54054054 L17.027027,4.54054054 Z M17.027027,3.02702703 C17.2359996,3.02702703 17.4054054,3.1964328 17.4054054,3.40540541 C17.4054054,3.61437801 17.2359996,3.78378378 17.027027,3.78378378 C16.8180544,3.78378378 16.6486486,3.61437801 16.6486486,3.40540541 C16.6489352,3.19655162 16.8181732,3.02731361 17.027027,3.02702703 Z" id="Shape" fill={color} fillRule="nonzero"></path>
                <path d="M19.9459459,4.54054054 C20.5728638,4.54054054 21.0810811,4.03232323 21.0810811,3.40540541 C21.0810811,2.77848758 20.5728638,2.27027027 19.9459459,2.27027027 C19.3190281,2.27027027 18.8108108,2.77848758 18.8108108,3.40540541 C18.8115146,4.03203149 19.3193199,4.53983677 19.9459459,4.54054054 L19.9459459,4.54054054 Z M19.9459459,3.02702703 C20.1549186,3.02702703 20.3243243,3.1964328 20.3243243,3.40540541 C20.3243243,3.61437801 20.1549186,3.78378378 19.9459459,3.78378378 C19.7369733,3.78378378 19.5675676,3.61437801 19.5675676,3.40540541 C19.5678542,3.19655162 19.7370922,3.02731361 19.9459459,3.02702703 Z" id="Shape" fill={color} fillRule="nonzero"></path>
                <path d="M19.7395577,10.0540541 L4.26044226,10.0540541 C4.05689751,10.0540541 3.89189189,10.2200026 3.89189189,10.4247104 L3.89189189,20.0617761 C3.89189189,20.2664839 4.05689751,20.4324324 4.26044226,20.4324324 L19.7395577,20.4324324 C19.9431025,20.4324324 20.1081081,20.2664839 20.1081081,20.0617761 L20.1081081,10.4247104 C20.1081081,10.2200026 19.9431025,10.0540541 19.7395577,10.0540541 Z M19.3710074,19.6911197 L4.62899263,19.6911197 L4.62899263,10.7953668 L19.3710074,10.7953668 L19.3710074,19.6911197 Z" id="Shape" fill={color} fillRule="nonzero"></path>
                <path d="M5.56756757,18.8108108 L18.4324324,18.8108108 C18.641405,18.8108108 18.8108108,18.6656059 18.8108108,18.4864865 C18.8108108,18.3073671 18.641405,18.1621622 18.4324324,18.1621622 L5.56756757,18.1621622 C5.35859496,18.1621622 5.18918919,18.3073671 5.18918919,18.4864865 C5.18918919,18.6656059 5.35859496,18.8108108 5.56756757,18.8108108 Z" id="Path" fill={color} fillRule="nonzero"></path>
                <path d="M2.97081081,7.45945946 L21.0291892,7.45945946 C21.2369677,7.45945946 21.4054054,7.31425451 21.4054054,7.13513514 C21.4054054,6.95601576 21.2369677,6.81081081 21.0291892,6.81081081 L2.97081081,6.81081081 C2.76303233,6.81081081 2.59459459,6.95601576 2.59459459,7.13513514 C2.59459459,7.31425451 2.76303233,7.45945946 2.97081081,7.45945946 Z" id="Path" fill={color} fillRule="nonzero"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
