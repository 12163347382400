import React from 'react';

export default function OvenCrossed({ color }) {
  return(
    <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>oven-crossed</title>
      <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact-Oven" transform="translate(-145.000000, -302.000000)" fill={color}>
          <g id="no" transform="translate(20.000000, 292.000000)">
            <g id="Group-2" transform="translate(0.000000, 13.000000)">
              <g id="oven-crossed" transform="translate(128.000000, 0.000000)">
                <path d="M22.9783784,0.648648649 L1.34594595,0.648648649 C1.13995866,0.648648649 0.972972973,0.815634337 0.972972973,1.02162162 L0.972972973,22.6540541 C0.972972973,22.8600413 1.13995866,23.027027 1.34594595,23.027027 L22.9783784,23.027027 C23.1843657,23.027027 23.3513514,22.8600413 23.3513514,22.6540541 L23.3513514,1.02162162 C23.3513514,0.815634337 23.1843657,0.648648649 22.9783784,0.648648649 Z M22.6054054,22.2810811 L1.71891892,22.2810811 L1.71891892,5.87027027 L22.6054054,5.87027027 L22.6054054,22.2810811 Z M22.6054054,5.12432432 L1.71891892,5.12432432 L1.71891892,1.39459459 L22.6054054,1.39459459 L22.6054054,5.12432432 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M9.13513514,4.21621622 L15.1891892,4.21621622 C15.3981618,4.21621622 15.5675676,4.04681045 15.5675676,3.83783784 L15.5675676,2.32432432 C15.5675676,2.11535172 15.3981618,1.94594595 15.1891892,1.94594595 L9.13513514,1.94594595 C8.92616253,1.94594595 8.75675676,2.11535172 8.75675676,2.32432432 L8.75675676,3.83783784 C8.75675676,4.04681045 8.92616253,4.21621622 9.13513514,4.21621622 Z M9.51351351,2.7027027 L14.8108108,2.7027027 L14.8108108,3.45945946 L9.51351351,3.45945946 L9.51351351,2.7027027 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M4.05405405,4.21621622 C4.68097188,4.21621622 5.18918919,3.70799891 5.18918919,3.08108108 C5.18918919,2.45416326 4.68097188,1.94594595 4.05405405,1.94594595 C3.42713623,1.94594595 2.91891892,2.45416326 2.91891892,3.08108108 C2.91962269,3.70770716 3.42742797,4.21551245 4.05405405,4.21621622 L4.05405405,4.21621622 Z M4.05405405,2.7027027 C4.26302666,2.7027027 4.43243243,2.87210847 4.43243243,3.08108108 C4.43243243,3.29005369 4.26302666,3.45945946 4.05405405,3.45945946 C3.84508145,3.45945946 3.67567568,3.29005369 3.67567568,3.08108108 C3.67596226,2.87222729 3.84520027,2.70298929 4.05405405,2.7027027 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M6.97297297,4.21621622 C7.5998908,4.21621622 8.10810811,3.70799891 8.10810811,3.08108108 C8.10810811,2.45416326 7.5998908,1.94594595 6.97297297,1.94594595 C6.34605515,1.94594595 5.83783784,2.45416326 5.83783784,3.08108108 C5.83854161,3.70770716 6.34634689,4.21551245 6.97297297,4.21621622 L6.97297297,4.21621622 Z M6.97297297,2.7027027 C7.18194558,2.7027027 7.35135135,2.87210847 7.35135135,3.08108108 C7.35135135,3.29005369 7.18194558,3.45945946 6.97297297,3.45945946 C6.76400036,3.45945946 6.59459459,3.29005369 6.59459459,3.08108108 C6.59488118,2.87222729 6.76411919,2.70298929 6.97297297,2.7027027 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M17.3513514,4.21621622 C17.9782692,4.21621622 18.4864865,3.70799891 18.4864865,3.08108108 C18.4864865,2.45416326 17.9782692,1.94594595 17.3513514,1.94594595 C16.7244335,1.94594595 16.2162162,2.45416326 16.2162162,3.08108108 C16.21692,3.70770716 16.7247253,4.21551245 17.3513514,4.21621622 L17.3513514,4.21621622 Z M17.3513514,2.7027027 C17.560324,2.7027027 17.7297297,2.87210847 17.7297297,3.08108108 C17.7297297,3.29005369 17.560324,3.45945946 17.3513514,3.45945946 C17.1423787,3.45945946 16.972973,3.29005369 16.972973,3.08108108 C16.9732596,2.87222729 17.1424976,2.70298929 17.3513514,2.7027027 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M20.2702703,4.21621622 C20.8971881,4.21621622 21.4054054,3.70799891 21.4054054,3.08108108 C21.4054054,2.45416326 20.8971881,1.94594595 20.2702703,1.94594595 C19.6433524,1.94594595 19.1351351,2.45416326 19.1351351,3.08108108 C19.1358389,3.70770716 19.6436442,4.21551245 20.2702703,4.21621622 L20.2702703,4.21621622 Z M20.2702703,2.7027027 C20.4792429,2.7027027 20.6486486,2.87210847 20.6486486,3.08108108 C20.6486486,3.29005369 20.4792429,3.45945946 20.2702703,3.45945946 C20.0612977,3.45945946 19.8918919,3.29005369 19.8918919,3.08108108 C19.8921785,2.87222729 20.0614165,2.70298929 20.2702703,2.7027027 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M20.0638821,9.72972973 L4.58476658,9.72972973 C4.38122184,9.72972973 4.21621622,9.89567824 4.21621622,10.1003861 L4.21621622,19.7374517 C4.21621622,19.9421596 4.38122184,20.1081081 4.58476658,20.1081081 L20.0638821,20.1081081 C20.2674268,20.1081081 20.4324324,19.9421596 20.4324324,19.7374517 L20.4324324,10.1003861 C20.4324324,9.89567824 20.2674268,9.72972973 20.0638821,9.72972973 Z M19.6953317,19.3667954 L4.95331695,19.3667954 L4.95331695,10.4710425 L19.6953317,10.4710425 L19.6953317,19.3667954 Z" id="Shape" fillRule="nonzero"></path>
                <path d="M5.89189189,18.4864865 L18.7567568,18.4864865 C18.9657294,18.4864865 19.1351351,18.3412815 19.1351351,18.1621622 C19.1351351,17.9830428 18.9657294,17.8378378 18.7567568,17.8378378 L5.89189189,17.8378378 C5.68291928,17.8378378 5.51351351,17.9830428 5.51351351,18.1621622 C5.51351351,18.3412815 5.68291928,18.4864865 5.89189189,18.4864865 Z" id="Path" fillRule="nonzero"></path>
                <path d="M3.29513514,7.13513514 L21.3535135,7.13513514 C21.561292,7.13513514 21.7297297,6.98993019 21.7297297,6.81081081 C21.7297297,6.63169143 21.561292,6.48648649 21.3535135,6.48648649 L3.29513514,6.48648649 C3.08735666,6.48648649 2.91891892,6.63169143 2.91891892,6.81081081 C2.91891892,6.98993019 3.08735666,7.13513514 3.29513514,7.13513514 Z" id="Path" fillRule="nonzero"></path>
                <path d="M27.525937,10.3522183 L27.6091981,12.3504844 L-3.52593702,13.6477817 L-3.60919811,11.6495156 L27.525937,10.3522183 Z" id="Rectangle" stroke={color} strokeWidth="1" transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) "></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
