import React from 'react';
import QuoteLayout from '../../components/QuoteLayout/quote-layout';
import QuoteContext from '../../context/quote-context';
import Oven from '../../components/GetQuoteSteps/oven';

export default function QuoteOvenPage() {
  return (
    <QuoteLayout>
      <QuoteContext.Consumer>
        {quote => (
          <Oven set={quote.set} oven={quote.oven} />
        )}
      </QuoteContext.Consumer>
    </QuoteLayout>
  );
}
